import { QueryClient, useMutation } from "@tanstack/react-query";
import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";
import {
  createPeriodicSchedule,
  deletePeriodicSchedule,
  updatePeriodicSchedule,
} from "../services/periodicScheduleApi";
import ReactQueryKeys from "./ReactQueryKeys";

export const useMutationDeletePeriodicSchedule = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(deletePeriodicSchedule, {
    onError: () =>
      getNotification({
        message: "Removing periodic schedule failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.PERIODIC_SCHEDULES]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Periodic schedule removed successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationUpdatePeriodicSchedule = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(updatePeriodicSchedule, {
    onError: () =>
      getNotification({
        message: "The update of periodic schedule has failed.",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.PERIODIC_SCHEDULES]);
      queryClient.invalidateQueries([ReactQueryKeys.PERIODIC_SCHEDULES_INFO]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Periodic schedule has been successfully updated.",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationCreatePeriodicSchedule = (
  queryClient: QueryClient,
  setLastRefreshTime: React.Dispatch<React.SetStateAction<Date>>
) =>
  useMutation(createPeriodicSchedule, {
    onError: () =>
      getNotification({
        message: "Periodic schedule creation failed",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.PERIODIC_SCHEDULES]);
      queryClient.invalidateQueries([ReactQueryKeys.PERIODIC_SCHEDULES_INFO]);
      setLastRefreshTime(new Date());
      getNotification({
        message: "Periodic schedule created successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });
