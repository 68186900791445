import { CheckCircleOutlined, CloseCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import "./Status.scss"

const Status = ({ isActive, switchMode }: { isActive: boolean, switchMode: boolean }) => {
  return (
    <Tag
    className="tag-style"
      icon={isActive && !switchMode ? <CheckCircleOutlined /> : !isActive && !switchMode ? <CloseCircleOutlined /> : <SyncOutlined spin />}
      color={isActive && !switchMode ? "success" : !isActive && !switchMode ? "error" : "processing"}
    >
      {isActive && !switchMode ? "On" : !isActive && !switchMode ? "off" : "waiting"}
    </Tag>
  );
};

export default Status;
