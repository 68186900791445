import { QueryClient, useMutation } from "@tanstack/react-query";
import { changeStateGroup, removeGroup } from "../services/listGroupApi";
import getNotification, {
  NotificationType,
} from "../components/Applications/ThemeNotification";
import { editGroup, saveGroup } from "../services/manageGroupApi";
import ReactQueryKeys from "./ReactQueryKeys";

export const useChangeStateGroupMutation = (
  queryClient: QueryClient,
  updateSwitchingState: (groupId: number, switching: boolean) => void
) =>
  useMutation(changeStateGroup, {
    onMutate: (variables) => {
      updateSwitchingState(variables.groupId, true);
    },
    onError: (_error, variables) => {
      updateSwitchingState(variables.groupId, false);
      getNotification({
        message: "Adding application failed",
        type: NotificationType.ERROR,
      });
    },
    onSuccess: (_data, variables) => {
      updateSwitchingState(variables.groupId, false);
      queryClient.invalidateQueries([ReactQueryKeys.GROUPS]);
      getNotification({
        message: "Group's state updated successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useDeleteGroupMutation = (queryClient: QueryClient) =>
  useMutation(removeGroup, {
    onError: () =>
      getNotification({
        message: "Fail to delete group",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GROUPS]);
      getNotification({
        message: "Group deleted successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationSaveGroup = (queryClient: QueryClient) =>
  useMutation(saveGroup, {
    onError: () =>
      getNotification({
        message: "Fail to save group",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GROUPS]);
      getNotification({
        message: "Group saved successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });

export const useMutationEditGroup = (queryClient: QueryClient) =>
  useMutation(editGroup, {
    onError: () =>
      getNotification({
        message: "Fail to update group",
        type: NotificationType.ERROR,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GROUPS]);
      getNotification({
        message: "Group updated successfully",
        type: NotificationType.SUCCESS,
      });
    },
  });
