export enum Day {
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
}

export interface DaySchedule {
  day: Day;
  startHour: string;
  endHour: string;
  isScheduleActive: boolean;
}

export interface PeriodicSchedule {
  name: string;
  periodicId: number;
  appId: number;
  state: boolean;
  daySchedules: DaySchedule[];
  isScheduleActive: boolean;
}
