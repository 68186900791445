import { Breadcrumb, Card, ConfigProvider, Steps } from "antd";
import { useContext, useState } from "react";
import { ClusterContext } from "../HomePage/HomePage";

import { useNavigate } from "react-router-dom";
import AddIngressForm from "../../components/Applications/AddIngressForm";
import AddResourceForm from "../../components/Applications/AddResourceForm";
import FormMode from "../../models/Form";
import {
  useMutationEditApplication,
  useMutationSaveApplication,
} from "../../mutations/useMutationApplication";
import { useQueryClient } from "@tanstack/react-query";
import { Resource } from "../../models/Resource";

const AddEditAppsPage = ({
  appId,
  appName,
  mode,
}: {
  appId: number | undefined;
  appName: string | undefined;
  mode: FormMode;
}) => {
  const navigate = useNavigate();
  const [applicationName, setApplicationName] = useState<string>("");
  const [resources, setResources] = useState<Resource[]>([]);
  const [current, setCurrent] = useState(0);

  const handleSave = (ingressIds: number[]) => {
    saveApplicationMutation.mutate({
      appName: applicationName!,
      resourcesIds: resources.map((ressource) => ressource.id),
      ingressIds: ingressIds,
      clusterId: cluster.id!,
    });
  };

  const handleEdit = (ingressIds: number[]) => {
    editApplicationMutation.mutate({
      appId: Number(appId!),
      appName: applicationName!,
      resourcesIds: resources.map((ressource) => ressource.id),
      ingressIds: ingressIds,
    });
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Resources",
      content: (
        <AddResourceForm
          mode={mode}
          appId={appId}
          next={next}
          applicationName={applicationName}
          setApplicationName={setApplicationName}
          resources={resources}
          setResources={setResources}
        />
      ),
    },
    {
      title: "Ingresses",
      content: (
        <AddIngressForm
          mode={mode}
          appId={appId}
          prev={prev}
          process={mode === FormMode.ADD ? handleSave : handleEdit}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  const generateBreadcrumbItems = () => {
    const breadcrumbItems = [
      {
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        title: <a onClick={() => navigate("/manage-app")}>Manage App</a>,
      },
    ];

    switch (mode) {
      case FormMode.ADD:
        breadcrumbItems.push({ title: <span>Add App</span> });
        break;
      case FormMode.EDIT:
        breadcrumbItems.push({ title: <span>{appName}</span> });
        break;
    }

    return breadcrumbItems;
  };

  const breadcrumbItems = generateBreadcrumbItems();
  const queryClient = useQueryClient();

  const { cluster } = useContext(ClusterContext);
  const saveApplicationMutation = useMutationSaveApplication(queryClient);
  const editApplicationMutation = useMutationEditApplication(queryClient);

  return (
    <>
      <Breadcrumb>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item key={index}>{item.title}</Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <br />
      <ConfigProvider
        theme={{
          components: {
            Steps: {
              fontSize: 20,
              controlHeight: 40,
            },
          },
        }}
      >
        <div style={{ width: "50%", margin: "auto" }}>
          <Steps current={current} items={items} />
        </div>
      </ConfigProvider>

      <br />

      <Card>{steps[current].content}</Card>
    </>
  );
};

export default AddEditAppsPage;
