import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, FloatButton, Switch, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useContext, useState } from "react";
import Status from "../../components/Applications/Status";
import CombinedScheduleFormPage from "../../components/ScheduleForms/CombinedScheduleFormPage";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import Error from "../../components/LoadingAndError/Error";
import Loading from "../../components/LoadingAndError/Loading";
import DeleteModal from "../../components/Modal/DeleteModal";
import { PeriodicSchedule } from "../../models/PeriodicSchedules";
import { UnifiedSchedule } from "../../models/UnifiedSchedule";
import {
  useMutationDeletePeriodicSchedule,
  useMutationUpdatePeriodicSchedule,
} from "../../mutations/useMutationPeriodicSchedule";
import { useMutationDeleteSchedule } from "../../mutations/useMutationSchedule";
import { fetchPeriodicSchedulesByApplication } from "../../services/periodicScheduleApi";
import { fetchSchedulesByApplication } from "../../services/scheduleApi";
import FormMode from "../../models/Form";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import DangerZone from "../../components/DangerZone/DangerZone";
import { ClusterContext } from "../HomePage/HomePage";
import { AppSchedule } from "../../models/Schedule";

const UnifiedSchedulePage = ({ appId }: { appId: number }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [selectedSchedule, setSelectedSchedule] = useState<
    UnifiedSchedule | undefined
  >();
  const [modalMode, setModalMode] = useState<FormMode>(FormMode.ADD);

  const queryClient = useQueryClient();
  const { cluster } = useContext(ClusterContext);
  const { LastUpdateTimeComponent, setLastRefreshTime } = useLastUpdateTime();

  const appSchedulesQuery = useQuery([ReactQueryKeys.APP_SCHEDULES], () =>
    fetchSchedulesByApplication(appId!)
  );
  const periodicSchedulesQuery = useQuery(
    [ReactQueryKeys.PERIODIC_SCHEDULES],
    () => fetchPeriodicSchedulesByApplication(appId!)
  );

  const deleteAppScheduleMutation = useMutationDeleteSchedule(
    queryClient,
    setLastRefreshTime
  );
  const deletePeriodicScheduleMutation = useMutationDeletePeriodicSchedule(
    queryClient,
    setLastRefreshTime
  );

  const updatePeriodicScheduleMutation = useMutationUpdatePeriodicSchedule(
    queryClient,
    setLastRefreshTime
  );

  const unifiedSchedules: UnifiedSchedule[] = [
    ...(appSchedulesQuery.data || [])
      .filter((schedule: AppSchedule) => !schedule.periodicScheduleId)
      .map((schedule: AppSchedule) => ({
        key: `AppSchedule-${schedule.id}`,
        id: schedule.id,
        periodicScheduleId: schedule.periodicScheduleId,
        name: schedule.name,
        type: "AppSchedule" as const,
        state: schedule.isActive,
        startDate: new Date(schedule.startDate),
        endDate: new Date(schedule.endDate),
        isScheduleActive: schedule.isScheduleActive,
      })),
    ...(periodicSchedulesQuery.data || []).map(
      (schedule: PeriodicSchedule) => ({
        key: `PeriodicSchedule-${schedule.periodicId}`,
        id: schedule.periodicId,
        name: schedule.name,
        type: "PeriodicSchedule" as const,
        state: schedule.state,
        daySchedules: schedule.daySchedules,
        isScheduleActive: schedule.isScheduleActive,
      })
    ),
  ];

  const handleRemoveSchedule = (scheduleId: number) => {
    if (selectedSchedule) {
      if (selectedSchedule.type === "AppSchedule") {
        deleteAppScheduleMutation.mutate({ scheduleId: scheduleId });
      } else {
        deletePeriodicScheduleMutation.mutate({
          scheduleId: scheduleId,
        });
      }
      setIsModalDeleteOpen(false);
    }
  };

  const openEditModal = (schedule: UnifiedSchedule) => {
    setModalMode(FormMode.EDIT);
    setSelectedSchedule(schedule);
    setIsModalOpen(true);
  };

  const changeIsActiveSchedule = (
    unifiedSchedule: UnifiedSchedule,
    newIsScheduleActive: boolean
  ) => {
    if (unifiedSchedule.type === "PeriodicSchedule") {
      updatePeriodicScheduleMutation.mutate({
        scheduleId: unifiedSchedule.id,
        name: unifiedSchedule.name,
        state: unifiedSchedule.state,
        daySchedules: unifiedSchedule.daySchedules!,
        isScheduleActive: newIsScheduleActive,
      });
    }
  };

  const columns: ColumnsType<UnifiedSchedule> = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Details",
      key: "details",
      render: (_, record: UnifiedSchedule) =>
        record.type === "AppSchedule"
          ? `${record.startDate?.toLocaleString()} - ${record.endDate?.toLocaleString()}`
          : `Multiple rules`,
    },
    {
      title: "Active",
      key: "isScheduleActive",
      render: (record: UnifiedSchedule) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {record.type === "PeriodicSchedule" ? (
            <span>
              <Switch
              defaultChecked={true}
                checked={record.isScheduleActive}
                onChange={(checked) => {
                  changeIsActiveSchedule(record, checked);
                }}
              />
            </span>
          ) : (
            <span>
            </span>
          )}
        </div>
      ),
    },
    {
      title: "State",
      key: "isActive",
      render: (record: UnifiedSchedule) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <span>
            <Status isActive={record.state} switchMode={false} />
          </span>
          <div>
            <Button
              onClick={() => {
                openEditModal(record);
              }}
            >
              Edit
            </Button>
            <span style={{ margin: "0 10px" }}></span>
            <Button
              type="primary"
              onClick={() => {
                setSelectedSchedule(record);
                setIsModalDeleteOpen(true);
              }}
            >
              Remove
            </Button>
          </div>
        </div>
      ),
    },
  ];

  if (appSchedulesQuery.isLoading || periodicSchedulesQuery.isLoading) {
    return <Loading />;
  }

  if (appSchedulesQuery.isError || periodicSchedulesQuery.isError) {
    return <Error height="80vh" />;
  }

  return (
    <>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      <Table columns={columns} dataSource={unifiedSchedules} rowKey="key" />
      <FloatButton
        shape="circle"
        tooltip="Add Schedule"
        type="primary"
        style={{ right: 94, width: 60, height: 60 }}
        icon={<PlusOutlined />}
        onClick={() => {
          setModalMode(FormMode.ADD);
          setIsModalOpen(true);
        }}
      />
      {isModalOpen && (
        <CombinedScheduleFormPage
          mode={modalMode}
          appId={appId}
          schedule={selectedSchedule}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setLastRefreshTime={setLastRefreshTime}
        />
      )}
      <DeleteModal
        id={selectedSchedule?.id}
        isModalDeleteOpen={isModalDeleteOpen}
        setIsModalDeleteOpen={setIsModalDeleteOpen}
        handleRemove={handleRemoveSchedule}
      />
    </>
  );
};

export default UnifiedSchedulePage;
