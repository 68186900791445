import Navigation from "./Navigation";
import {
  Drawer as AntDrawer,
  Button,
  MenuProps,
  Switch,
  Typography,
} from "antd";
import popapp_logo from "../../assets/popapp_logo_full.png";
import popapp_logo_dark from "../../assets/popapp_logo_full_dark.png";
import Dropdown from "antd/es/dropdown/dropdown";
import "./Drawer.module.scss";
import { useContext } from "react";
import { SettingsContext } from "../Context/SettingsContext";
import { KeycloakAuthenticatorContext } from "../Context/KeyCloakAuthenticator/KeyCloakAuthenticator";

const Drawer = () => {
  const { isDarkMode, toggleTheme } = useContext(SettingsContext);
  const { logout } = useContext(KeycloakAuthenticatorContext);

  const items: MenuProps["items"] = [
    {
      key: "DarkMode",
      label: (
        <div style={{ display: "flex", marginBottom: "8px" }}>
          <Typography style={{ marginRight: "16px" }}>Dark Mode</Typography>
          <Switch
            checked={isDarkMode}
            onChange={toggleTheme}
            style={{ marginLeft: "auto" }}
          />
        </div>
      ),
      disabled: true,
      style: { cursor: "default" },
    },
    {
      key: "Logout",
      label: (
        <div onClick={() => logout()} style={{ marginTop: "8px" }}>
          Logout
        </div>
      ),
    },
  ];

  return (
    <AntDrawer
      title={
        <img
          src={isDarkMode ? popapp_logo_dark : popapp_logo}
          alt="PopApp"
          style={{ width: "70%" }}
        />
      }
      placement="left"
      closable={false}
      open={true}
      width={300}
      mask={false}
      footer={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Dropdown menu={{ items }} placement="top" trigger={["click"]}>
            <Button type="text" block>
              Settings
            </Button>
          </Dropdown>
        </div>
      }
    >
      <Navigation />
    </AntDrawer>
  );
};

export default Drawer;
