import axios from "axios";
import { DaySchedule, PeriodicSchedule } from "../models/PeriodicSchedules";

export const fetchPeriodicSchedulesByApplication = async (
  appId: number
): Promise<PeriodicSchedule[]> => {
  const result = await axios.get<PeriodicSchedule[]>(
    `${Config.apiBaseUrl}/applications/${appId}/periodic-schedules`
  );
  return result.data;
};

export const fetchPeriodicSchedulesById = async ({
  scheduleId,
}: {
  scheduleId: number;
}): Promise<PeriodicSchedule> => {
  const result = await axios.get<PeriodicSchedule>(
    `${Config.apiBaseUrl}/applications/periodic-schedules/${scheduleId}`
  );
  return result.data;
};

export const createPeriodicSchedule = async ({
  appId,
  name,
  state,
  daySchedules,
  isScheduleActive,
}: {
  appId: number;
  name: string;
  state: boolean;
  daySchedules: DaySchedule[];
  isScheduleActive: boolean;
}): Promise<DaySchedule[]> => {
  const result = await axios.post<DaySchedule[]>(
    `${Config.apiBaseUrl}/applications/${appId}/periodic-schedules`,
    {
      name: name,
      state: state,
      daySchedules: daySchedules,
      isScheduleActive: isScheduleActive
    }
  );
  return result.data;
};

export const updatePeriodicSchedule = async ({
  scheduleId,
  name,
  state,
  daySchedules,
  isScheduleActive,
}: {
  scheduleId: number;
  name: string;
  state: boolean;
  daySchedules: DaySchedule[];
  isScheduleActive: boolean
}): Promise<DaySchedule[]> => {
  const result = await axios.patch<DaySchedule[]>(
    `${Config.apiBaseUrl}/applications/periodic-schedules/${scheduleId}`,
    {
      name: name,
      state: state,
      daySchedules: daySchedules,
      isScheduleActive: isScheduleActive,
    }
  );
  return result.data;
};

export const deletePeriodicSchedule = async ({
  scheduleId,
}: {
  scheduleId: number;
}): Promise<PeriodicSchedule> => {
  const result = await axios.delete<PeriodicSchedule>(
    `${Config.apiBaseUrl}/applications/periodic-schedules/${scheduleId}`
  );
  return result.data;
};
