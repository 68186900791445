import { useContext, useState } from "react";
import { Button, Space, Table, Tooltip } from "antd";
import { getApplicationDetails } from "../../services/listApplicationsApi";
import { ColumnsType } from "antd/es/table";
import { Resource } from "../../models/Resource";
import SetReplicaColumn from "../../components/Applications/SetReplicaColumn";
import { SyncOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import Loading from "../../components/LoadingAndError/Loading";
import Error from "../../components/LoadingAndError/Error";
import { useLastUpdateTime } from "../../components/GetRefreshTime/LastUpdateTime";
import {
  useMutationHandleResourceReplicas,
  useMutationResourceSwitch,
  useMutationSyncApp,
} from "../../mutations/useMutationApplication";
import Status from "../../components/Applications/Status";
import ReactQueryKeys from "../../mutations/ReactQueryKeys";
import WarningModal from "../../components/Modal/WarningModal";
import DangerZone from "../../components/DangerZone/DangerZone";
import { ClusterContext } from "../HomePage/HomePage";

const ApplicationResourcesPage = ({
  appId,
  groupId,
}: {
  appId: number;
  groupId?: number;
}) => {
  const queryClient = useQueryClient();
  const { setLastRefreshTime, LastUpdateTimeComponent } = useLastUpdateTime();
  const [switchingStates, setSwitchingStates] = useState<{
    [key: string]: boolean;
  }>({});
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [selectedResourceId, setSelectedResourceId] = useState<number>(0);
  const [clickedLineState, setClickedLineState] = useState(false);
  const { cluster } = useContext(ClusterContext);

  const {
    data: appResources,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [ReactQueryKeys.APP_RESOURCES],
    queryFn: () => getApplicationDetails(appId),
  });

  const generateKey = (resourceId: number, groupId?: number): string => {
    return groupId ? `${resourceId}_${groupId}` : `${resourceId}`;
  };

  const handleResourceReplicasMutation =
    useMutationHandleResourceReplicas(queryClient);

  const handleResourceReplicas = async (
    replicasAmount: number,
    resourceId: number,
    groupId?: number
  ) => {
    handleResourceReplicasMutation.mutate({
      replicasAmount,
      resourceId,
      groupId,
    });
  };

  const handleSyncMutation = useMutationSyncApp(
    queryClient,
    setLastRefreshTime
  );

  const handleSync = async () => {
    handleSyncMutation.mutate();
    setLastRefreshTime(new Date());
  };
  const handleResourceSwitchMutation = useMutationResourceSwitch(queryClient);

  const handleResourceSwitch = (
    isActive: boolean,
    resourceId: number,
    groupId?: number
  ) => {
    const key = generateKey(resourceId, groupId);
    setSwitchingStates((prev) => ({ ...prev, [key]: true }));
    handleResourceSwitchMutation.mutate(
      {
        isActive,
        appId,
        resourceId,
        groupId,
      },
      {
        onSuccess: () => {
          setSwitchingStates((prev) => ({ ...prev, [key]: false }));
        },
        onError: () => {
          setSwitchingStates((prev) => ({ ...prev, [key]: false }));
        },
      }
    );
  };

  const columns: ColumnsType<Resource> = [
    {
      title: "Resource Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Current Replicas",
      dataIndex: "currentReplicasAmount",
      key: "currentReplicasAmount",
      align: "right" as const,
    },
    {
      title: "Set Replicas",
      dataIndex: "setReplicasAmount",
      key: "setReplicasAmount",
      render: (_: unknown, resource: Resource) => {
        return (
          <SetReplicaColumn
            resource={resource}
            handleResourceSwitch={handleResourceReplicas}
            groupId={groupId}
          />
        );
      },
      align: "right" as const,
    },
    {
      title: "State",
      dataIndex: "isActive",
      key: "state",
      render: (_: unknown, resource: Resource) => {
        const state = resource.currentReplicasAmount > 0;
        const key = generateKey(resource.id, groupId);
        const isSwitching = switchingStates[key] || false;
        return <Status isActive={state} switchMode={isSwitching} />;
      },
    },
    {
      title: () => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            Action
            <Tooltip title="Synchronize">
              <Button icon={<SyncOutlined />} onClick={handleSync} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: "isActive",
      key: "isActive",
      render: (_: unknown, resource: Resource) => {
        const state = resource.currentReplicasAmount > 0;
        const key = generateKey(resource.id, groupId);
        const isSwitching = switchingStates[key] || false;
        return (
          <Space>
            <Button
              type="default"
              onClick={(event) => {
                event.stopPropagation();
                setClickedLineState(!state);
                setSelectedResourceId(resource.id);
                setIsWarningModalOpen(true);
              }}
              disabled={isSwitching}
            >
              {!state ? "On" : "Off"}
            </Button>
          </Space>
        );
      },
    },
  ];

  return isLoading ? (
    <Loading />
  ) : isError ? (
    <Error height="80vh" />
  ) : (
    <>
      <LastUpdateTimeComponent />
      <DangerZone isSensitive={cluster.sensitive} />
      {appResources ? (
        <Table
          className="text"
          columns={columns}
          dataSource={appResources.resources.sort((a, b) =>
            a.name.localeCompare(b.name)
          )}
          rowKey="id"
          loading={isLoading}
        />
      ) : null}
      <WarningModal
        primaryId={selectedResourceId}
        secondaryId={groupId}
        isActive={clickedLineState}
        name={
          appResources.resources.find((item) => item.id === selectedResourceId)
            ?.name
        }
        isWarningModalOpen={isWarningModalOpen}
        setIsWarningModalOpen={setIsWarningModalOpen}
        handleSwitch={handleResourceSwitch}
      />
    </>
  );
};

export default ApplicationResourcesPage;
